import * as React from 'react';

// this hook notifies the parent window that the iframe has loaded.
// if we don't send this message, the parent window will reload the whole
// page ever 30 seconds.

export default function useLoadedMessage(): void {
    React.useEffect(() => {
        if (window.self !== window.parent) {
            // we're in an iframe => post the "loaded" event
            window.parent.postMessage('loaded', '*');
        }
    }, []);
}
