import { gql } from '@apollo/client';
import { WaitTimeSettings } from '@bondvet/types/waitTimeModel';
import { Location } from '@bondvet/types/locations';

export interface WaitTime {
    _id: string;
    date: Date;
    firstName: string;
    lastName: string;
    pet: string;
    status: string;
}

export interface WaitTimesQueryResult {
    waitTimes: readonly WaitTime[];
    waitTimeSettings: WaitTimeSettings;
    frontOfHouseClinicScreens: readonly string[];
}

export const waitTimes = gql`
    query waitTimes {
        waitTimes {
            _id
            date
            firstName
            lastName
            pet
            status
        }
        waitTimeSettings {
            timezone
            showAppointmentStatus
        }
        frontOfHouseClinicScreens
    }
`;

export interface LocationsQueryVariables {
    __noAuth: true;
}

export interface LocationsQueryResult {
    locations: readonly Pick<Location, '_id' | 'name' | 'address'>[];
}

export const locations = gql`
    query locations {
        locations {
            _id
            name
            address
        }
    }
`;
