import * as React from 'react';
import { useLocation, Redirect } from 'react-router-dom';
import extendToken from 'lib/extendToken';
import { getLocationId, setRefreshToken } from 'lib/storage';

const Init: React.FC = () => {
    const location = useLocation();
    const [locationId, setLocationId] = React.useState('');
    const redirect = React.useMemo<string | null>(() => {
        if (locationId) {
            const params = new URLSearchParams(location.search);

            params.delete('token');
            const path = params.get('path');

            let targetPath = `/${locationId}`;

            if (path) {
                params.delete('path');
                params.set('fixed-path', 'true');
                targetPath = `${targetPath}/${
                    path.startsWith('/') ? path.substring(1) : path
                }`;
            }

            const queryString = params.toString();

            if (queryString) {
                return `${targetPath}?${queryString}`;
            }

            return targetPath;
        }

        return null;
    }, [location.search, locationId]);

    React.useEffect(() => {
        const params = new URLSearchParams(location.search);
        const token = params.get('token');

        if (token) {
            extendToken(token as string).then((tokenAndLocationOrNull) => {
                if (tokenAndLocationOrNull) {
                    setLocationId(tokenAndLocationOrNull.locationId);
                }

                return setRefreshToken(token as string);
            });
        } else {
            getLocationId().then((locationIdOrNull) => {
                if (locationIdOrNull) {
                    setLocationId(locationIdOrNull);
                }
            });
        }
    }, [location]);

    if (redirect) {
        return <Redirect to={redirect} />;
    }

    return <p>Loading</p>;
};

export default Init;
