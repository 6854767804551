import * as React from 'react';
import { queries } from 'api/index';
import ScreenImage from 'components/ScreenImage/ScreenImage';
import Page from '../Page';
import logo from 'assets/logo.svg';
import { WaitTimeSettings } from '@bondvet/types/waitTimeModel';
import { WaitTime, WaitTimesQueryResult } from 'api/queries';
import client from 'lib/client';
import { useStyles } from './styles';

const SLIDE_TIMER_INTERVAL = 12 * 1000;
const RELOAD_TIMER_INTERVAL = 15 * 60 * 1000;
const POLL_INTERVAL = 60 * 1000;
const APPTS_PER_PAGE = 8;
const WAIT_TIMES = 'waitTimes';
const FRONT_OF_HOUSE_CLINIC_SCREENS = 'frontOfHouseClinicScreens';

interface Slide {
    type: typeof WAIT_TIMES | typeof FRONT_OF_HOUSE_CLINIC_SCREENS;
    data: any;
}

interface SlidesData {
    waitTimes: readonly WaitTime[];
    waitTimeSettings: WaitTimeSettings;
    frontOfHouseClinicScreens: readonly string[];
}

export default function SlideController() {
    const { classes } = useStyles();
    const slideTimer = React.useRef<NodeJS.Timeout | null>(null);
    const reloadTimer = React.useRef<NodeJS.Timeout | null>(null);
    const [currentSlide, setCurrentSlide] = React.useState(0);
    const [slides, setSlides] = React.useState<Slide[]>([]);
    const [slidesData, setSlidesData] = React.useState<SlidesData>({
        waitTimes: [],
        waitTimeSettings: {
            showAppointmentStatus: false,
            timezone: 'America/New_York',
        },
        frontOfHouseClinicScreens: [],
    });

    React.useEffect(() => {
        let interval: number;
        const loadData = async () => {
            const { data }: { data: WaitTimesQueryResult } = await client.query(
                {
                    query: queries.waitTimes,
                    fetchPolicy: 'no-cache',
                },
            );

            if (data) {
                const {
                    waitTimes,
                    waitTimeSettings,
                    frontOfHouseClinicScreens,
                } = data;

                setSlidesData((sData) => {
                    return {
                        ...sData,
                        waitTimes: waitTimes,
                        waitTimeSettings: waitTimeSettings,
                        frontOfHouseClinicScreens: frontOfHouseClinicScreens,
                    };
                });
            }
        };

        loadData().then(() => {
            interval = window.setInterval(loadData, POLL_INTERVAL);
        });

        return () => {
            if (interval) {
                window.clearInterval(interval);
            }
        };
    }, []);

    React.useEffect(() => {
        const fohComponents: Slide[] = [];
        const waitTimeComponents: Slide[] = [];
        const slideComponents: Slide[] = [];
        if (
            slidesData.frontOfHouseClinicScreens &&
            slidesData.frontOfHouseClinicScreens.length > 0
        ) {
            slidesData.frontOfHouseClinicScreens.forEach((image) => {
                fohComponents.push({
                    type: FRONT_OF_HOUSE_CLINIC_SCREENS,
                    data: image,
                });
            });
        }
        if (slidesData.waitTimes && slidesData.waitTimes.length > 0) {
            let index = 0;
            while (index < slidesData.waitTimes.length) {
                const appts = slidesData.waitTimes.slice(
                    index,
                    APPTS_PER_PAGE + index,
                );
                const key = appts[0]._id;
                waitTimeComponents.push({
                    type: WAIT_TIMES,
                    data: {
                        key,
                        appts,
                    },
                });
                index += APPTS_PER_PAGE;
            }
        }
        if (fohComponents.length > 0 && waitTimeComponents.length > 0) {
            for (let i = 0; i < fohComponents.length; i++) {
                slideComponents.push(fohComponents[i]);
                slideComponents.push(
                    ...waitTimeComponents.map((component) => {
                        return {
                            ...component,
                            data: {
                                ...component.data,
                                key: `${component.data.key}-${i}`,
                            },
                        };
                    }),
                );
            }
        } else {
            slideComponents.push(...fohComponents, ...waitTimeComponents);
        }
        setSlides(() => slideComponents);
    }, [slidesData]);

    const numberOfSlides = slides.length;

    React.useEffect(() => {
        const showNextSlide = () => {
            setCurrentSlide(
                (currentSlide2) => (currentSlide2 + 1) % numberOfSlides,
            );
        };

        const startSlideRotation = () => {
            if (!slideTimer.current) {
                slideTimer.current = setInterval(
                    showNextSlide,
                    SLIDE_TIMER_INTERVAL,
                );
            }
        };

        const stopSlideRotation = () => {
            if (slideTimer.current) {
                clearInterval(slideTimer.current);
                slideTimer.current = null;
            }
        };

        reloadTimer.current = setTimeout(() => {
            reloadTimer.current = null;
            window.location.reload();
        }, RELOAD_TIMER_INTERVAL);
        if (numberOfSlides > 0) {
            startSlideRotation();
        } else {
            stopSlideRotation();
        }
        return () => {
            stopSlideRotation();
            if (reloadTimer.current) {
                clearTimeout(reloadTimer.current);
            }
        };
    }, [numberOfSlides]);

    return (
        <div className={classes.root}>
            <img src={logo} alt="logo" className={classes.logo} />
            {slides.map(({ type, data }, idx) => {
                if (type === FRONT_OF_HOUSE_CLINIC_SCREENS) {
                    return (
                        <ScreenImage
                            key={data}
                            image={data}
                            active={idx === currentSlide}
                        />
                    );
                } else if (type === WAIT_TIMES) {
                    return (
                        <Page
                            key={data.key}
                            waitTimes={data.appts}
                            waitTimeSettings={slidesData.waitTimeSettings}
                            active={idx === currentSlide}
                        />
                    );
                }
            })}
        </div>
    );
}
