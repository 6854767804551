import backgroundImage from '../../assets/background.svg';
import { pxToRem } from 'lib/util';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    root: {
        width: '100vw',
        height: '100vh',
        backgroundColor: theme.palette.background.default,
        backgroundImage: `url(${backgroundImage})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'bottom center',
        backgroundSize: 'cover',
        position: 'relative',
        boxSizing: 'border-box',
        padding: pxToRem(60),
    },
}));
