import * as React from 'react';
import { ApolloProvider } from '@apollo/client';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { LocalizeProvider } from 'react-localize-redux';
import client from 'lib/client';
import initialize from 'lib/i18n';
import useLoadedMessage from 'hooks/useLoadedMessage';
import moment from 'moment-timezone';
import theme from './theme';
import ToggleFullscreen from '../ToggleFullscreen';
import Init from '../Init';
import { Layout } from '../Layout';
import SlideController from '../SlideController';

moment.locale('en');

export default function App(): React.ReactElement {
    useLoadedMessage();
    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme}>
                <Layout>
                    <LocalizeProvider initialize={initialize}>
                        <ApolloProvider client={client}>
                            <Router>
                                <Switch>
                                    <Route exact path="/">
                                        <Init />
                                    </Route>
                                    <Route path="/:locationId">
                                        <SlideController />
                                    </Route>
                                </Switch>
                            </Router>
                            <ToggleFullscreen />
                        </ApolloProvider>
                    </LocalizeProvider>
                </Layout>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}
