import { pxToRem } from 'lib/util';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    table: {
        position: 'absolute',
        top: 0,
        left: 0,
        transition: 'opacity 1s ease-in-out',
        opacity: 0,
        margin: pxToRem(56),
    },
    activeTable: {
        opacity: 1,
    },
    date: {
        fontWeight: 'normal',
        lineHeight: pxToRem(100),
        maxWidth: pxToRem(320),
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    status: {
        fontWeight: 'normal',
        lineHeight: pxToRem(100),
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    pet: {
        fontWeight: 'normal',
        lineHeight: pxToRem(100),
        maxWidth: pxToRem(320),
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    name: {
        fontWeight: 'normal',
        lineHeight: pxToRem(100),
        maxWidth: pxToRem(320),
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
    },
    header: {
        color: theme.palette.text.secondary,
        fontWeight: 300,
        fontSize: pxToRem(40),
        lineHeight: pxToRem(100),
    },
    cell: {
        paddingRight: pxToRem(50),
        '&:last-child': {
            paddingRight: 0,
        },
    },
}));
