export type GraphqlClientName = 'api' | 'auth';

export type GraphqlClientType = 'default' | 'auth';

type GraphqlClientNames = Readonly<{
    [key in GraphqlClientType]: GraphqlClientName;
}>;

export const GRAPHQL_CLIENT_NAMES = Object.freeze<GraphqlClientNames>({
    default: 'api',
    auth: 'auth',
});
