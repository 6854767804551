import { pxToRem } from 'lib/util';
import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    root: {
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
    },
    logo: {
        position: 'absolute',
        width: pxToRem(222),
        right: pxToRem(50),
        bottom: pxToRem(50),
    },
}));
