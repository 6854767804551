import { renderToStaticMarkup } from 'react-dom/server';
import translation from './translations';

export default {
    languages: [{ name: 'English', code: 'en' }],
    translation,
    options: {
        defaultLanguage: 'en',
        renderToStaticMarkup,
    },
};
