import * as React from 'react';

interface ScreenImageProps {
    image: string;
    active: boolean;
}

function ScreenImage({ image, active }: ScreenImageProps) {
    return (
        <div
            style={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                backgroundSize: 'cover',
                backgroundImage: `url(${image})`,
                opacity: active ? 1 : 0,
                transition: 'opacity 1s ease-in-out',
            }}
        />
    );
}

export default ScreenImage;
