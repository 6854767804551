import jwtDecode from 'jwt-decode';
import { getAccessToken, getRefreshToken } from './storage';

export interface JwtPayload {
    exp: number;
    sub: string;
}

export default async function getValidToken(): Promise<string> {
    const accessToken = await getAccessToken();

    if (accessToken) {
        const { exp } = jwtDecode<JwtPayload>(accessToken);
        const limit = Math.floor(Date.now() / 1000) + 60;

        if (limit < exp) {
            // token is valid for at least another minute
            return accessToken;
        }
    }

    const refreshToken = await getRefreshToken();

    if (refreshToken) {
        // eslint-disable-next-line import/no-cycle
        const { default: extendToken } = await import('./extendToken');
        const tokenAndLocationId = await extendToken(refreshToken);

        if (tokenAndLocationId) {
            return tokenAndLocationId.accessToken;
        }
    }

    throw new Error('no or expired access token and no refresh token');
}
