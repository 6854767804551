import colors from '@bondvet/web-app-lib/colors';
import { createTheme, alpha } from '@mui/material/styles';

export default createTheme({
    palette: {
        background: { default: colors.white },
        text: {
            primary: colors.primary,
            secondary: alpha(colors.secondary, 0.7),
        },
    },
    typography: {
        fontFamily: '"Sailec-Regular", sans-serif',
        fontSize: 18.66667,
        h1: {
            fontSize: '2rem',
            lineHeight: 1.22,
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 'bold',
        },
        body1: {
            fontSize: '1rem',
            fontFamily: 'Montserrat, sans-serif',
            fontWeight: 'bold',
        },
        body2: {
            fontSize: '1rem',
            fontWeight: 'normal',
        },
    },
    components: {
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: 'none',
                },
            },
        },
    },
});
