import * as React from 'react';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import IconButton from '@mui/material/IconButton';
import styles from './ToggleFullscreen.module.scss';
import { renderButton } from '../../lib/util';

const ToggleFullscreen: React.FC = () => {
    const [isFullscreen, setIsFullscreen] = React.useState<boolean>(
        !!document.fullscreenElement,
    );

    const handleFullscreenChange = () => {
        setIsFullscreen(!!document.fullscreenElement);
    };

    const enterFullscreen = () => {
        document.documentElement.requestFullscreen().then();
    };

    React.useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullscreenChange);

        return () => {
            document.removeEventListener(
                'fullscreenchange',
                handleFullscreenChange,
            );
        };
    });

    if (isFullscreen || !renderButton) {
        return null;
    }

    return (
        <IconButton
            onClick={enterFullscreen}
            className={styles.button}
            size="large"
        >
            <FullscreenIcon />
        </IconButton>
    );
};

export default ToggleFullscreen;
