import {
    ApolloClient,
    InMemoryCache,
    createHttpLink,
    Operation,
    ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import getValidToken from './getValidToken';
import { GRAPHQL_CLIENT_NAMES } from './constants';

const defaultLink = createHttpLink({
    uri: process.env.REACT_APP_API_URL,
    credentials: 'omit',
});

const authEndpointLink = createHttpLink({
    uri: process.env.REACT_APP_AUTH_API_URL,
    credentials: 'omit',
});

function authEndpointSplitter(operation: Operation): boolean {
    return operation.getContext().clientName === GRAPHQL_CLIENT_NAMES.auth;
}

const httpLink = ApolloLink.split(
    authEndpointSplitter,
    authEndpointLink,
    defaultLink,
);

const authLink = setContext(async ({ variables }, { headers: prevHeaders }) => {
    const headers = {
        ...prevHeaders,
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const context: Record<string, any> = { headers };

    if (variables?.__noAuth === true) {
        return context;
    }

    const accessToken = await getValidToken();

    if (accessToken) {
        headers.authorization = `Bearer ${accessToken}`;
    }

    return context;
});

const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: authLink.concat(httpLink),
});

export default client;
