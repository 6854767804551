import * as React from 'react';
import moment from 'moment-timezone';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import classnames from 'classnames';
import { WaitTimeSettings } from '@bondvet/types/waitTimeModel';
import { WaitTime } from 'api/queries';
import { useStyles } from './styles';

interface PageProps {
    waitTimes: readonly WaitTime[];
    waitTimeSettings: WaitTimeSettings;
    active: boolean;
}

function getStatusLabel(status: string) {
    switch (status) {
        case 'inProgress':
            return 'In Progress';
        case 'present':
            return 'Checked In';
        default:
            return 'Scheduled';
    }
}

function renderRow(
    { _id, date, status, firstName, lastName, pet }: WaitTime,
    { timezone, showAppointmentStatus }: WaitTimeSettings,
    classes: ReturnType<typeof useStyles>['classes'],
) {
    return (
        <TableRow key={_id}>
            <TableCell className={classes.cell}>
                <Typography className={classes.date} variant="caption">
                    {moment.tz(date, timezone).format('hh:mm A')}
                </Typography>
            </TableCell>
            {showAppointmentStatus && (
                <TableCell className={classes.cell}>
                    <Typography className={classes.status} variant="body2">
                        {getStatusLabel(status)}
                    </Typography>
                </TableCell>
            )}
            <TableCell className={classes.cell}>
                <Typography className={classes.name} variant="body2">
                    {firstName} {lastName && `${lastName.substring(0, 1)}.`}
                </Typography>
            </TableCell>
            <TableCell className={classes.cell}>
                <Typography className={classes.pet} variant="body2">
                    {pet}
                </Typography>
            </TableCell>
        </TableRow>
    );
}

export default function Page({
    active,
    waitTimes,
    waitTimeSettings,
}: PageProps) {
    const { classes } = useStyles();
    const className = classnames(classes.table, {
        [classes.activeTable]: active,
    });
    return (
        <Table className={className} padding="none">
            <TableHead>
                <TableRow>
                    <TableCell className={classes.header}>Visit Time</TableCell>
                    {waitTimeSettings.showAppointmentStatus && (
                        <TableCell className={classes.header}>
                            Visit Status
                        </TableCell>
                    )}
                    <TableCell className={classes.header}>Name</TableCell>
                    <TableCell className={classes.header}>Pet Name</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {waitTimes.map((entry) =>
                    renderRow(entry, waitTimeSettings, classes),
                )}
            </TableBody>
        </Table>
    );
}
